import React, {useContext, useEffect, useState} from 'react';
import {Container, Col, Row, Alert} from "react-bootstrap";
import useWindowDimensions from "hooks/Dimensions";
import ActionModal from "components/ActionModal";
import {NOTIFICATIONS_URL} from "service/UrlConstant";
import {SecurityContext} from "context/security";
import {NOTIFICATION_TYPE_ALERT, NOTIFICATION_TYPE_INFORMATION} from "./context/HomepageContext";
import parse from 'html-react-parser';

export default function PortalAlertHistory(props) {

    const initNotificationState = {
        notifications: []
    }

    const {isMobile, isDesktop} = useWindowDimensions();
    const bodyStyle=isDesktop?{paddingTop:0}:{};
    const [notificationData, setNotificationData] = useState(JSON.parse(JSON.stringify(initNotificationState)));
    const urlRegex = /(https?:\/\/[^\s\}\)\]\{\[\(]+)/g;
    //const urlRegex = (new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"));

    const {
        makeGetRequest
    } = useContext(SecurityContext);

    const loadNotifications = () => {
        const onSuccess = (response) => {
            const notificationDataState = {...notificationData};
            if (!response || !response.data || response.data.length === 0) {
                notificationDataState.notifications = [];
            } else {
                notificationDataState.notifications = response.data;
            }
            setNotificationData(notificationDataState);
        }
        const onError = (err) => {
            console.error(err);
        }

        makeGetRequest(NOTIFICATIONS_URL, {}, onSuccess, onError);
    };

    useEffect(()=>{
        loadNotifications();
    },[])

    const getNotificationTypeAlertVariant = (notificationType) => {

        switch (notificationType) {
            case NOTIFICATION_TYPE_ALERT:
                return 'warning'
            case NOTIFICATION_TYPE_INFORMATION:
                return 'info'
            default:
                return 'info'
        }
    }

    const modalBody=()=>(

        <Container fluid>
            {notificationData.notifications.map((alert, idx) => (
                <Row key={idx}>
                    <Col>
                        <Alert variant={getNotificationTypeAlertVariant(alert.notificationType)}>
                            <Container style={{padding:0, justifyContent:'flex-start', flexDirection:'row'}}>
                                <Row >
                                    <Col xs={"auto"} style={{paddingRight:3, paddingLeft:3, paddingTop:0}}>
                                        <span style={{opacity: alert.notificationType === 'ALERT' ? 1 : 0}} className="td-icon icon-small td-icon-star" />
                                    </Col>
                                    <Col style={{paddingLeft:0, paddingTop:1}}>
                                        <Container fluid style={{width:"100%", padding:0}}>
                                            <Row style={{paddingTop:0, paddingRight:0}}>
                                                <Col >
                                                    <Alert.Heading>
                                                        <b style={{verticalAlign:'top'}}>{
                                                            parse(alert.headerText.replace(urlRegex, function(url) {
                                                                return '<a href="' + url + '" target="_blank">' + url + '</a>';
                                                            }))}</b>
                                                    </Alert.Heading>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p>
                                                        {parse(alert.descriptionText.replace(urlRegex, function(url) {
                                                            return '<a href="' + url + '" target="_blank">' + url + '</a>';
                                                        }))}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>
                            </Container>
                        </Alert>
                    </Col>
                </Row>
            ))}
        </Container>
    )

    const modalTitle=()=>(
        <Container fluid className='p-0 m-0'>
            <Row>
                <Col> Alerts / Notifications / News / You should know</Col>
            </Row>
        </Container>
    )


    return(<>
        <ActionModal body={modalBody} title={modalTitle} show={props.show}  onHide={props.onHide} dialogClassName={isMobile?'mobile-fullscreen-dialog':''} size={"lg"} centered={!isMobile} bodyStyle={bodyStyle}/>
    </>);

}
