import React, {useContext, useEffect} from "react";
import {Row, Col, Alert, CloseButton, Container} from "react-bootstrap";
import {
    NOTIFICATIONS_ALERTS_READ_URL,
    NOTIFICATIONS_ALERTS_URL
} from "service/UrlConstant";
import {SecurityContext} from "context/security";
import {HomepageContext} from "./context";
import {ToastContext} from "../../context/Toast";
import parse from 'html-react-parser';


export default function PortalAlert(props) {

    const { loadedAlertsCallback} = {...props};
    const { portalAlertState, setPortalAlertState} = useContext(HomepageContext);
    const { error} = useContext(ToastContext);
    const urlRegex = /(https?:\/\/[^\s\}\)\]\{\[\(]+)/g;

    const {
        makeGetRequest,
        makePostRequest
    } = useContext(SecurityContext);

    const markAlertAsRead = (notificationId) => {

        const onSuccessResponse = (response) => {

            const afterReadPortalAlertState = {...portalAlertState};
            const alertReadIndexToRemove = afterReadPortalAlertState.alerts.map(alert => alert.notificationId).indexOf(notificationId);
            if(alertReadIndexToRemove > -1){
                afterReadPortalAlertState.alerts.splice(alertReadIndexToRemove, 1);
            }
            else {
                console.log('Cant find index of alert to remove:' + notificationId);
            }
            setPortalAlertState(afterReadPortalAlertState);
            loadedAlertsCallback(afterReadPortalAlertState.alerts.length > 0);
        }

        const onErrorResponse = (err) => {
            console.error(err);
            error("Error occurred with closing alert");
        }

        makePostRequest([], NOTIFICATIONS_ALERTS_READ_URL  + "/" + notificationId, onSuccessResponse, onErrorResponse);
    }

    const loadNotificationAlerts = () => {
        const onSuccess = (response) => {
            const updatedPortalAlertState = {...portalAlertState};
            if (!response || !response.data || response.data.length === 0) {
                updatedPortalAlertState.alerts = [];
            } else {
                updatedPortalAlertState.alerts = response.data;
            }
            setPortalAlertState(updatedPortalAlertState);
            loadedAlertsCallback(updatedPortalAlertState.alerts.length > 0);
        }
        const onError = (err) => {
            console.error(err);
        }

        makeGetRequest(NOTIFICATIONS_ALERTS_URL, {}, onSuccess, onError);
    };

    useEffect(() => {
        loadNotificationAlerts();
    }, []);

    return (
        <Container fluid>
            {portalAlertState.alerts.map((alert, idx) => (
                <Row key={idx}>
                    <Col>
                        <Alert variant="warning" style={{padding: 0, marginBottom: 0}}>
                            <Container fluid style={{width: "100%"}}>
                                <Row>
                                    <Col>
                                        <Alert.Heading>
                                            <b style={{verticalAlign:'top'}}>{
                                                parse(alert.headerText.replace(urlRegex, function(url) {
                                                    return '<a href="' + url + '" target="_blank">' + url + '</a>';
                                                }))}</b>
                                        </Alert.Heading>
                                        <p>
                                            {alert.descriptionText}
                                        </p>
                                    </Col>
                                    <Col xs={1}><CloseButton aria-label={"close"}
                                                             onClick={() => markAlertAsRead(alert.notificationId)}/>
                                    </Col>
                                </Row>

                            </Container>
                        </Alert>
                    </Col>
                </Row>
            ))}
        </Container>
    )
}
